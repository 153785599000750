<template>
    <v-app>
        <link href="https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css" rel="stylesheet">

        <!-- Start of Application Header -->
        <v-app-bar app dense color="#4682b4" dark elevation="4">
            <v-tabs slider-color="#4682b4">

                <!-- Holds Archived navigation options -- used to declutter the nav bar -->
                <v-menu left bottom v-if="$store.state.user">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small>mdi-archive-outline</v-icon>
                        </v-btn>
                    </template>

                    <!-- Archived associations & people links, activated by the v-btn above -->
                    <v-list dark color="#4682b4">
                        <v-list-item>
                            <v-tab v-if="$store.state.user" @click="$router.push({ path: '/associations/archivedAccounts' })">Associations</v-tab>
                            <v-tab v-if="$store.state.user" @click="$router.push({ path: '/associations/archivedPeople' })">People</v-tab>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-tab v-if="!$store.state.user" @click="$router.push({ path: '/LogIn' })"><v-icon medium>mdi-account-circle</v-icon></v-tab>
                <v-tab v-if="$store.state.user" @click="$router.push({ path: '/LogIn' })"><v-icon medium>mdi-account-check</v-icon></v-tab>
                <v-tab v-if="$store.state.user" @click="$router.push({ path: '/associations/associations' })"><v-icon medium>mdi-home-group</v-icon></v-tab>
                <v-tab v-if="$store.state.user" @click="$router.push({ path: '/personSearch' })"><v-icon medium>mdi-magnify</v-icon></v-tab>

                <!-- Spacer pushes link to the far right of the header -- remaining tabs are admin-related pages -->
                <v-spacer></v-spacer>
                <v-tab v-if="$store.state.user" @click="$router.push({ path: '/resetPassword' })"><v-icon medium>mdi-lock-reset</v-icon></v-tab>

                <!-- Tab for Creating a New User, Should Only Appear if the logged in user is an admin -->
                <template v-if="$store.state.user">
                    <v-tab v-if="$store.state.user.admin" @click="$router.push({ path: '/CreateUser' })"><v-icon medium>mdi-account-plus</v-icon></v-tab>
                    <v-tab v-if="$store.state.user.admin" @click="$router.push({ path: '/deleteUser' })"><v-icon medium>mdi-account-remove</v-icon></v-tab>
                </template> 

                <v-tab @click="goToMainSite"><v-icon medium>mdi-gavel</v-icon></v-tab>

            </v-tabs>
        </v-app-bar>

        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        drawer: false
    }),

    methods: {
        goToMainSite() {
            window.open("https://whhlaw.com");
        }
    }
};
</script>

<style>
    /* Change application background color */
    /* .v-main {
        background-color: #f5f5f5;
    } */
    body {
        background-color: var(--v-accent-lighten2);
    }
</style>


