<template>
  <v-container>

    <v-row class="text-center mt-8">
      <v-col cols="12">
      
        <v-img
          :src="require('../assets/whhlogo.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
           
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      //
    }),
  }
</script>
