import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,

    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],

    state: {
        token: null,
        user: null,
        isUserLoggedIn: false,

        // PersonID used to hold specific person id for database querying
        // associationID used to hold specific association id for database querying
        personID: null,
        associationID: null
    },

    mutations: {
        setToken(state, token) {
            state.token = token
            if (token) {
                state.isUserLoggedIn = true
            } else {
                state.isUserLoggedIn = false
            }
        },
        setUser(state, user) {
            state.user = user
        },
        setPersonID(state, personID) {
            state.personID = personID
        },
        setAssociationID(state, associationID) {
            state.associationID = associationID
        }
    },

    actions: {
        setToken({ commit }, token) {
            commit('setToken', token)
        },
        setUser({ commit }, user) {
            commit('setUser', user)
        },
        setPersonID({ commit }, personID) {
            commit('setPersonID', personID)
        },
        setAssociationID({ commit }, associationID) {
            commit('setAssociationID', associationID)
        }
    }
})