<template>
    <HomeComp />
</template>

<script>
import HomeComp from '../components/HomeComp.vue'

    export default {
        name: 'Home',

        components: {
            HomeComp,
        },
    }
</script>
