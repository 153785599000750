import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    { 
        path: '/', name: 'Home', 
        component: Home 
    },
    { 
        path: '*', name: "PageNotFound", 
        component: () => import('../views/PageNotFound.vue') 
    },
    { 
        path: '/associations/associations', name: 'Associations',
        component: () => import('../views/associations/Associations.vue')
    },
    { 
        path: '/people', name: 'People', 
        component: () => import('../views/People.vue') 
    },
    { 
        path: '/resetPassword', name: 'resetPassword', 
        component: () => import('../views/resetPassword.vue') 
    },
    { 
        path: '/deleteUser', name: 'deleteUser', 
        component: () => import('../views/deleteUser.vue') 
    },
    { 
        path: '/createuser', name: 'CreateUser', 
        component: () => import('../views/CreateUser.vue') 
    },
    { 
        path: '/LogIn', name: 'LogIn', 
        component: () => import('../views/LogIn.vue') 
    },
    {
        path: '/associations/associationsAdd', name: 'associationsAdd',
        component: () => import('../views/associations/addNewAssociation.vue')
    },
    {
        path: '/associations/peopleAdd/:association_id', name: 'peopleAdd',
        component: () => import('../views/associations/addNewPerson.vue')
    },
    
    {
        path: '/associations/associationsEdit/:association_id', name: 'associationsEdit',
        component: () => import('../views/associations/associationsEdit.vue')
    },
    {
        path: '/associations/associationsView/:association_id', name: 'associationsView',
        component: () => import('../views/associations/associationsView.vue')
    },
    { 
        path: '/associations/addNewAssociation', name: 'addNewAssociation',
        component: () => import('../views/associations/addNewAssociation.vue')
    },
    {
        path: '/associations/addAssessment', name: 'addAssessment',
        component: () => import('../views/associations/addAssessment.vue')
    },
    {
        path: '/associations/addPayment', name: 'addPayment',
        component: () => import('../views/associations/addPayment.vue')
    },
    {
        path: '/associations/addReoccurring', name: 'addReoccurring',
        component: () => import('../views/associations/addReoccurring.vue')
    },
    {
        path: '/associations/peopleDetails/:person_id', name: 'peopleDetails',
        component: () => import('../views/associations/peopleDetails.vue')
    },
    {
        path: '/associations/personEdit/association_id=:association_id&person_id=:person_id', name: 'personEdit',
        component: () => import('../views/associations/personEdit.vue')
    },
    { 
        path: '/personSearch', name: 'personSearch', 
        component: () => import('../views/personSearch.vue') 
    },
    {
        path: '/associations/archivedAccounts', name: 'archivedAccounts',
        component: () => import('../views/associations/archivedAccounts.vue')
    },
    {
        path: '/associations/archivedByAssociation/:association_id', name: 'archivedByAssociation',
        component: () => import('../views/associations/archivedByAssociation.vue')
    },
    {
        path: '/associations/archivedPeople', name: 'archivedPeople',
        component: () => import('../views/associations/archivedPeople.vue')
    },
    {
        path: '/associations/archivedAssociationsView/:association_id', name: 'archivedAssociationsView',
        component: () => import('../views/associations/archivedAssociationsView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router